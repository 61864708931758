var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"index"}},[_c('dv-full-screen-container',{staticClass:"bg"},[_c('div',{staticClass:"host-body"},[_c('Menu',{attrs:{"name":"video-monitor"},on:{"change":function($event){return _vm.onProInfoChange($event)}}}),(_vm.loading)?_c('dv-loading',[_vm._v("Loading...")]):_c('div',{attrs:{"id":"content"}},[_c('div',{attrs:{"id":"left"}},[_c('div',{staticClass:"leftMain"},[_c('div',{staticClass:"monitor-device-list-link flex-column align-center module",staticStyle:{"position":"relative","z-index":"1"}},_vm._l((_vm.menu),function(item){return _c('div',{key:item.id,staticClass:"monitor-device-item-link flex-row align-center",class:_vm.currentKey == item.id ? 'active' : '',on:{"click":function($event){return _vm.makeActive(item.id)}}},[_c('div',[_c('span',{staticClass:"trans-o-left"},[_vm._v(_vm._s(item.name))])])])}),0)])]),_c('div',{attrs:{"id":"center"}},[_c('div',{staticClass:"mapContainer",style:({
              backgroundImage: _vm.bgImg,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
            })},_vm._l((_vm.showDevices),function(item){return _c('div',{key:item.Id,style:({
                left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                position: 'absolute',
              }),attrs:{"name":item.Name},on:{"click":function($event){return _vm.clickDevice(item)}}},[(item.DeviceMethod == 5)?_c('div',[_c('img',{staticClass:"position",attrs:{"id":item.Id,"name":item.Name,"title":item.Name,"src":require("@/assets/image/position-tower.png")}}),_c('span',{staticClass:"position",attrs:{"title":item.Name}},[_vm._v(" "+_vm._s(item.Name)+" ")])]):(item.DeviceMethod == 9)?_c('div',[_c('img',{staticClass:"position",style:({
                    left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                    top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                  }),attrs:{"id":item.Id,"name":item.Name,"title":item.Name,"src":require("@/assets/image/position-elevator.png")}}),_c('span',{staticClass:"position",attrs:{"title":item.Name}},[_vm._v(" "+_vm._s(item.Name)+" ")])]):(item.DeviceMethod == 6)?_c('div',[_c('img',{staticClass:"position",style:({
                    left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                    top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                  }),attrs:{"id":item.Id,"name":item.Name,"title":item.Name,"src":require("@/assets/image/position-unloading.png")}}),_c('span',{staticClass:"position",attrs:{"title":item.Name}},[_vm._v(" "+_vm._s(item.Name)+" ")])]):(item.DeviceMethod == 99)?_c('div',[_c('img',{staticClass:"position",style:({
                    left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                    top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                  }),attrs:{"id":item.Id,"name":item.Name,"title":item.Name,"src":require("@/assets/image/position-led.png")}}),_c('span',{staticClass:"position",attrs:{"title":item.Name}},[_vm._v(" "+_vm._s(item.Name)+" ")])]):(item.DeviceMethod == 3)?_c('div',[_c('img',{staticClass:"position",style:({
                    left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                    top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                  }),attrs:{"id":item.Id,"name":item.Name,"title":item.Name,"src":require("@/assets/image/position-video.png")}}),_c('span',{staticClass:"position",attrs:{"title":item.Name}},[_vm._v(" "+_vm._s(item.Name)+" ")])]):(item.DeviceMethod == 2)?_c('div',[_c('img',{staticClass:"position",style:({
                    left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                    top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                  }),attrs:{"id":item.Id,"name":item.Name,"title":item.Name,"src":require("@/assets/image/position-evn.png")}}),_c('span',{staticClass:"position",attrs:{"title":item.Name}},[_vm._v(" "+_vm._s(item.Name)+" ")])]):(item.DeviceMethod == 1)?_c('div',[_c('img',{staticClass:"position",style:({
                    left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                    top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                  }),attrs:{"id":item.Id,"name":item.Name,"title":item.Name,"src":require("@/assets/image/position-door.png")}}),_c('span',{staticClass:"position",attrs:{"title":item.Name}},[_vm._v(" "+_vm._s(item.Name)+" ")])]):_vm._e()])}),0)])])],1),_c('tower',{ref:"tower",attrs:{"parentObj":this}}),_c('env',{ref:"env",attrs:{"parentObj":this}}),_c('v-ideo',{ref:"video",attrs:{"parentObj":this}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }