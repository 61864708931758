<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <Menu name="video-monitor"
              @change="onProInfoChange($event)" />

        <dv-loading v-if="loading">Loading...</dv-loading>
        <div v-else
             id="content">
          <!-- 左边 -->
          <div id="left">
            <div class="leftMain">
              <div class="monitor-device-list-link flex-column align-center module"
                   style="position: relative; z-index: 1">
                <div class="monitor-device-item-link flex-row align-center"
                     v-for="item in menu"
                     v-bind:key="item.id"
                     v-bind:class="currentKey == item.id ? 'active' : ''"
                     v-on:click="makeActive(item.id)">
                  <div>
                    <span class="trans-o-left">{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="center">
            <div class="mapContainer"
                 :style="{
                backgroundImage: bgImg,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              }">
              <div v-for="item in showDevices"
                   v-bind:key="item.Id"
                   v-bind:name="item.Name"
                   v-on:click="clickDevice(item)"
                   :style="{
                  left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                  top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                  position: 'absolute',
                }">
                <div v-if="item.DeviceMethod == 5">
                  <img v-bind:id="item.Id"
                       v-bind:name="item.Name"
                       v-bind:title="item.Name"
                       src="@/assets/image/position-tower.png"
                       class="position" />
                  <span v-bind:title="item.Name"
                        class="position">
                    {{ item.Name }}
                  </span>
                </div>
                <div v-else-if="item.DeviceMethod == 9">
                  <img v-bind:id="item.Id"
                       v-bind:name="item.Name"
                       v-bind:title="item.Name"
                       src="@/assets/image/position-elevator.png"
                       class="position"
                       :style="{
                      left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                      top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                    }" />
                  <span v-bind:title="item.Name"
                        class="position">
                    {{ item.Name }}
                  </span>
                </div>
                <div v-else-if="item.DeviceMethod == 6">
                  <img v-bind:id="item.Id"
                       v-bind:name="item.Name"
                       v-bind:title="item.Name"
                       src="@/assets/image/position-unloading.png"
                       class="position"
                       :style="{
                      left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                      top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                    }" />
                  <span v-bind:title="item.Name"
                        class="position">
                    {{ item.Name }}
                  </span>
                </div>
                <div v-else-if="item.DeviceMethod == 99">
                  <img v-bind:id="item.Id"
                       v-bind:name="item.Name"
                       v-bind:title="item.Name"
                       src="@/assets/image/position-led.png"
                       class="position"
                       :style="{
                      left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                      top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                    }" />
                  <span v-bind:title="item.Name"
                        class="position">
                    {{ item.Name }}
                  </span>
                </div>
                <div v-else-if="item.DeviceMethod == 3">
                  <img v-bind:id="item.Id"
                       v-bind:name="item.Name"
                       v-bind:title="item.Name"
                       src="@/assets/image/position-video.png"
                       class="position"
                       :style="{
                      left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                      top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                    }" />
                  <span v-bind:title="item.Name"
                        class="position">
                    {{ item.Name }}
                  </span>
                </div>
                <div v-else-if="item.DeviceMethod == 2">
                  <img v-bind:id="item.Id"
                       v-bind:name="item.Name"
                       v-bind:title="item.Name"
                       src="@/assets/image/position-evn.png"
                       class="position"
                       :style="{
                      left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                      top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                    }" />
                  <span v-bind:title="item.Name"
                        class="position">
                    {{ item.Name }}
                  </span>
                </div>
                <div v-else-if="item.DeviceMethod == 1">
                  <img v-bind:id="item.Id"
                       v-bind:name="item.Name"
                       v-bind:title="item.Name"
                       src="@/assets/image/position-door.png"
                       class="position"
                       :style="{
                      left: (item.Pos ? item.Pos.Lat : 0) + 'px',
                      top: (item.Pos ? item.Pos.Lng : 0) + 'px',
                    }" />
                  <span v-bind:title="item.Name"
                        class="position">
                    {{ item.Name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div id="right"></div> -->
        </div>
      </div>
      <tower ref="tower"
             :parentObj="this"></tower>
      <env ref="env"
           :parentObj="this"></env>
      <v-ideo ref="video"
              :parentObj="this"></v-ideo>
    </dv-full-screen-container>
  </div>
</template>

<script>
moment.locale("zh-cn");
import moment from "moment";
import ProInfoCache from "@/utils/cache/ProInfoCache";
import Menu from "@/components/Menu";
import tower from "./tower";
import env from "./env";
import VIdeo from "./video";
export default {
  data () {
    return {
      loading: true,
      visible: false,
      pro: {},
      connection: {},
      time: {},
      date: {},
      zhouji: {},
      bgImg: "url(" + require("@/assets/image/morenditu.png") + ")",
      devices: {},
      showDevices: [],

      menu: [],
      currentKey: 0,
    };
  },
  components: {
    Menu,
    tower,
    env,
    VIdeo,
  },

  created: function () {
  },
  mounted () {
    this.pro = ProInfoCache.getProInfo();
    this.initForm()
  },
  watch: {},
  methods: {
    onProInfoChange (e) {
      this.pro = e;
      this.initForm()
    },
    initForm () {
      this.loadData();
      this.getTime();
      this.changeTiming();
      this.cancelLoading();
    },
    getTime () {
      this.time = moment().format("HH:mm");
      this.date = moment().format("MMM Do");
      this.zhouji = moment().format("ddd");
      setInterval(() => {
        this.time = moment().format("HH:mm");
      }, 60000);
    },
    // 根据自己的业务情况修改
    changeTiming () {
      setInterval(() => { }, 1000 * 10 * 60);
    },
    loadData () {
      this.$http
        .post("/Projects/ProImage/GetTheProjectImageData", {
          ProId: this.pro.Id,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success && resJson.Data)
            this.bgImg = "url(" + resJson.Data + ")";
        });
      this.fetchEventsList();
    },
    fetchEventsList () {
      if (!this.pro.Id) return;
      this.loading = true;
      this.$http
        .post("/DeviceManage/DeviceBase/GetDeviceSelfPosInfo", {
          ProId: this.pro.Id,
        })
        .then((resJson) => {
          this.loading = false;
          this.devices = resJson.Data;
          this.showDevices = this.devices;
          this.initMenu();
        });
    },
    initMenu () {
      let arr = [];
      arr.push({ id: 0, name: "所有" });
      for (let index = 0; index < this.devices.length; index++) {
        const element = this.devices[index];
        arr.push({ id: element.DeviceMethod, name: element.DeviceMethodStr });
      }
      const res = new Map();
      this.menu = arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },
    makeActive (key) {
      this.currentKey = key;
      if (this.currentKey == 0) this.showDevices = this.devices;
      else
        this.showDevices = this.devices.filter(function (item) {
          return item.DeviceMethod == key;
        });
    },
    cancelLoading () {
      setTimeout(() => {
        this.fetchEventsList();
      }, 10 * 60 * 60 * 1000);
    },
    clickDevice (d) {
      this.visible = true;
      switch (d.DeviceMethod) {
        case 2:
          this.$refs.env.openForm(d);
          break;
        case 3:
          this.$refs.video.openForm(d);
          break;
        case 5:
          this.$refs.tower.openForm(d);
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.danger {
  color: #fb497c !important;
}
.noml {
  color: #24e974 !important;
}
.huang {
  color: #ffb70e !important;
}
#content {
  display: flex;
  width: 100%;
  padding: 0.5rem 0.3rem 0 0.3rem;
  height: 10.3rem;
  overflow: hidden;
  h3 {
    font-weight: 900;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgb(249 242 242 / 85%);
  }
  #left {
    height: 100%;
    .leftMain {
      width: 2.14rem;
      background: url("../../assets/image/g_zuoxia.png") no-repeat center center;
      background-size: 100% 100%;
    }
  }

  #center {
    width: 1430px;
    height: 784px;
    margin-left: 1.4%;
  }

  #right {
    width: 22.22%;
    height: 100%;
    margin-left: 1.4%;
  }
}
.mapContainer {
  // width: auto;
  height: 100%;
  margin: 0 auto;
  background: #03263d;
  position: relative;
}
.monitor-device-item-link {
  cursor: pointer;
  background-color: rgb(15 19 37);
  margin: 0.025rem 0px;
  height: 0.35rem;
}
.active,
.monitor-device-item-link:hover {
  background-color: #1a5cd7;
}
.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -moz-box;
}
.align-center {
  align-items: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  text-align: center;
}
.trans-o-left {
  padding-left: 8.61111px;
  transform: scale(0.645833);
  font-size: 10.5px;
  color: hsla(0, 0%, 100%, 0.8);
  transform-origin: left center 0;
  -webkit-transform-origin: left center 0;
  -ms-transform-origin: left center 0;
}
.position {
  cursor: pointer;
  /*  position: absolute; */
  top: 179px;
  left: 661px;
  width: 40px;
  height: 40px;
  z-index: 1000;
  color: #fff;
  text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  -webkit-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  -moz-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
}
.align-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
