<template>
  <drag-modal :visible="visible"
              width="4.5rem"
              @cancel="() => (visible = false)">
    <div class="flex-row justify-between align-center"
         slot="title">
      <div class="flex-row flex-1">
        <span class="font-bold trans-o-left"
              style="transform: scale(0.931944); font-size: 11.1833px">{{ title }}</span>
      </div>
    </div>

    <div class="flex-column center"
         style="width: 100%; height: 300px; position: relative;">
      <video ref="videoPlayer"
             class="video-js vjs-default-skin"
             style="width: 100%; height: 100%; object-fit: fill"></video>
    </div>
  </drag-modal>
</template>
<script>
import DragModal from "@/components/DragModal";
import 'videojs-contrib-hls'

//import VueAliplayerV2 from "@/components/AliplayerV2";
export default {
  data () {
    return {
      loading: true,
      visible: false,
      title: "",
      player: null,
      options: {
        autoplay: true,
        controls: true,
        muted: false,
        bigPlayButton: true,
        textTrackDisplay: false,
        posterImage: false,
        errorDisplay: false,
        hls: {
          withCredentials: true
        }
      },
    };
  },
  components: {
    DragModal,
    //VueAliplayerV2,
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    openForm (device) {
      this.visible = true;
      this.loading = true;
      this.title = device.Name;
      this.$http
        .post("/DeviceManage/DeviceVideoCamera/GetTheData", { id: device.Id })
        .then((resJson) => {
          this.loading = false;
          if (!this.player) {
            this.player = this.$video(this.$refs.videoPlayer, this.options)
          }
          this.player.src([
            {
              src: this.convertAddress(resJson.Data.LiveAddress),
              type: 'application/x-mpegURL'
            }
          ])
          this.player.play()
        });
    },
    convertAddress (address) {
      var a = address.replace(/^https?:\/\//i, "//")
      console.log(a)
      return a
    },
  },
};
</script>
<style scoped>
.align-center {
  align-items: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  text-align: center;
}
.device-list li {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
</style>